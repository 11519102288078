import React from "react";
//import { useStaticQuery, graphql } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image";
import {
  useIntl,
  Link,
  //FormattedMessage,
  //changeLocale,
} from "gatsby-plugin-react-intl";

const ComponentTextblock4 = ({ content }) => {
  const intl = useIntl();

  return (
    <section className="sect sect_TreatmentCvd_2f8as2">
      <div className="container">
        <p className="h3 font-weight-bold">
          {intl.locale === "en"
            ? `Your mobility journey starts here`
            : `Ihre Reise der Bewegung startet hier`}
        </p>
        <p>
          {intl.locale === "en"
            ? `Find out how Cyberdyne can help.`
            : `Finden Sie heraus wie Cyberdyne Ihnen dabei hilft.`}
        </p>
        <div>
          <Link to="/contact/" className="link-button">
            {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ComponentTextblock4;
