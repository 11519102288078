import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ComponentTextblock1 from "../../components/component-textblock-1";
import FooterCta from "../../components/footer-cta";

import { useIntl, Link } from "gatsby-plugin-react-intl";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const PageCompanIndex = ({ data, location }) => {
  const intl = useIntl();
  return (
    <Layout location={location} mode="white">
      <Seo
        title={
          intl.locale === "en"
            ? `Cyberdyne Inc. – Founder. Innovation for the future, Society 5.0`
            : `Cyberdyne Inc. - Innovationen für die Zukunft, Gesellschaft 5.0`
        }
        description={
          intl.locale === "en"
            ? `Cybernics Revolution – technology which has a course of power to support, assist, enhance, and regenerate functions of humans.`
            : `Cybernics Revolution - Technologie mit der Fähigkeit zu unterstützen, verbessern und regenerieren von menschlichen Funktionen.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/2VWnF6TQy7YUC8Ntwof3zY/126c64d02930a9653dc3c7d0f7c1fbcd/E186.png?w=1200&h=630&fit=fill"
      />
      <section className="sect sect_PageMission_catch sect-bg">
        <div className="container">
          <ComponentTextblock1
            title={
              intl.locale === "en"
                ? `The Heart of Cybernics Revolution - Envision for Society 5.0`
                : `Das Herz der Cybernics Revolution - Vision für die Gesellschaft 5.0`
            }
            subtitle={
              intl.locale === "en" ? `Cyberdyne Inc.` : `Cyberdyne Inc.`
            }
            content=""
          />

          <div className="flex-row mx-n2 mt-4 pict-3">
            <div className="px-2">
              <StaticImage
                src="../../images/company/cyberdyne/image-cyberdyne-1@2x.png"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/company/cyberdyne/image-cyberdyne-2@2x.png"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/company/cyberdyne/image-cyberdyne-3@2x.png"
                className="mb-4"
                alt=""
              />
            </div>
          </div>

          <div className="flex-row">
            <div className="flex-column-7">
              <h2 className="h3">
                {intl.locale === "en"
                  ? `The significant existence of technology is to support, assist,
  enhance, and regenerate functions of humans.`
                  : `Die entscheidende Aufgabe der existierende Technologie ist die menschliche Funktion zu unterstützen, verbessern und regenerieren.`}
              </h2>
              <p className="message-sm">
                {intl.locale === "en"
                  ? `CYBERDYNE was established in June 2004 in Japan as a university
  venture to solve various social problems that the super-aged
  Japanese society must face. Our business is to create an ideal
  future by researching, developing, and manufacturing innovative
  Cybernics systems, with a focus on improving medicine, welfare,
  daily life, and the workplace.`
                  : `CYBERDYNE wurde im Juni 2004 in Japan als Universitätsunternehmen gegründet, um verschiedene soziale Probleme der überalternden japanischen Gesellschaft zu lösen. Unser Ziel ist es, eine ideale Zukunft zu schaffen, indem wir innovative Cybernics-Systeme erforschen, entwickeln und herstellen. Der Schwerpunkt liegt dabei auf der Verbesserung der medizinischen Verfahren, des Wohlbefindens, des Alltags und des Arbeitsplatzes.`}
              </p>
              <p className="message-sm">
                {intl.locale === "en"
                  ? `The company name is derived from its utilization of innovative
                “Cybernics*” technologies and the suffix “dyne” which is derived
                from the Greek word dunamis meaning force/power. “CYBERDYNE”
                therefore means “power generated by Cybernics” and expresses the
                company spirit that all employees hold dearly.`
                  : `Der Firmenname leitet sich aus der Nutzung innovativer "Cybernics*"-Technologien und dem Suffix "dyne" ab, das vom griechischen Wort dunamis für Kraft abgeleitet ist. "CYBERDYNE" bedeutet also "Kraft, die durch Cybernics erzeugt wird" und drückt den Unternehmensgeist aus, der allen Mitarbeitern am Herzen liegt.`}
              </p>
              <p className="message-sm">
                {intl.locale === "en"
                  ? `*Cybernics is a coined term by Dr. Yoshiyuki Sankai, founder of
                Cyberdyne. Cybernics is a new cross-disciplinary academic field
                whose core disciplines are related to human, robots and
                information systems.`
                  : `*Cybernics ist ein von Dr. Yoshiyuki Sankai, dem Gründer von Cyberdyne, geprägter Begriff. Cybernics ist ein neues interdisziplinäres akademisches Feld, dessen Kerndisziplinen mit Menschen, Robotern und Informationssystemen zu tun haben.`}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="founder" className="sect sect_PageMission_catch sect-bg-bls">
        <div className="container">
          <div className="flex-row justify-content-between">
            <div className="flex-column-7">
              <h2 className="h3">
                {intl.locale === "en"
                  ? `Creator of the Future. Our Society emerges to where physical
                space and cyber space are often overlapped. Cybernics technology
                helps us uniting Humans, Robotics, and Information system.`
                  : `Gestalter unser Zukunft. Unsere Gesellschaft entwickelt sich so, dass der physische und der cyber Raum immer mehr überlappen. Die Cybernics-Technologie hilft dabei Menschen, Roboter und Informationssysteme zu vereinen.`}
              </h2>
              <p className="message-sm">
                {intl.locale === "en" ? (
                  <div>
                    Prof. Dr. Yoshiyuki SANKAI earned a Ph.D. in Engineering
                    from University of Tsukuba in Japan in 1987. He has
                    progressed from being a research fellow at the JSPS to
                    assistant professor, associate professor, and then professor
                    at the Graduate School of Systems and Information
                    Engineering, University of Tsukuba. Dr. Sankai was also a
                    visiting professor at Baylor College of Medicine in Houston,
                    Texas in the United States.
                    <br />
                    <br />
                    Currently, he is a professor, the Executive Research
                    Director at the Center for Cybernics Research and the
                    Director at the F-MIRAI, University of Tsukuba; and the
                    President and CEO of CYBERDYNE Inc.
                  </div>
                ) : (
                  <div>
                    Prof. Dr. Yoshiyuki SANKAI promovierte 1987 in
                    Ingenieurwissenschaften an der Universität Tsukuba in Japan.
                    Er hat sich von einem Forschungsstipendiaten an der JSPS zum
                    Assistenzprofessor, außerordentlichen Professor und dann zum
                    Professor an der Graduate School of Systems and Information
                    Engineering der Universität Tsukuba entwickelt. Dr. Sankai
                    war außerdem Gastprofessor am Baylor College of Medizin in
                    Houston, Texas, in den Vereinigten Staaten.
                    <br />
                    <br />
                    Derzeit ist er Professor, Executive Research Director am
                    Center for Cybernics Research und Direktor am F-MIRAI der
                    Universität Tsukuba sowie Präsident und CEO von CYBERDYNE
                    Inc.
                  </div>
                )}
              </p>
              <p className="message-sm">
                {intl.locale === "en"
                  ? `He successively held the position of Leader/PM for the FIRST
                program and ImPACT program, both initiated by the Japanese
                Cabinet Office, and the Business producer of the Program for
                Building Regional Innovation Ecosystem by MEXT. He also served
                as an Executive Board Member of Advanced Robotics, and a member
                of Global Agenda Council on Robotics & Smart Devices of the WEF.
                Now, he is an Executive Board Member of the Japan Society of
                Embolus Detection and Treatment, and a fellow of the Robotics
                Society of Japan (“RSJ”) and the Society of Instrument and
                Control Engineers (“SICE”). He is a Center Partner of the Center
                for the Fourth Industrial Revolution, a member of Global Future
                Council of Production, and a member of Global Precision Medicine
                Council of the WEF. And he is also appointed as an International
                Fellow of the Royal Swedish Academy of Engineering
                Sciences(“IVA”).`
                  : `Er hatte nacheinander die Position des Leiters/PM für das FIRST-Programm und das ImPACT-Programm inne, die beide vom japanischen Kabinett initiiert wurden, sowie die des Business-Produzenten des Programms zum Aufbau eines regionalen Innovations-Ökosystems des MEXT. Er war auch Vorstandsmitglied von Advanced Robotics und Mitglied des Global Agenda Council on Robotics & Smart Devices des WEF. Jetzt ist er Vorstandsmitglied der Japan Society of Embolus Detection and Treatment und ein Fellow der Robotics Society of Japan (RSJ) und der Society of Instrument and Control Engineers (SICE). Er ist ein Center-Partner des Center for the Fourth Industrial Revolution, Mitglied des Global Future Council of Production und Mitglied des Global Precision Medicine Council des WEF. Außerdem ist er zum International Fellow der Royal Swedish Academy of Engineering ernannt worden.`}
              </p>
            </div>

            <div className="flex-column-4">
              <StaticImage
                src="../../images/company/cyberdyne/image.jpeg"
                className="mb-4"
                alt=""
              />
              <p className="message-label-bold txt txt-rot">
                {intl.locale === "en"
                  ? `Prof. Dr. Yoshiyuki SANKAI`
                  : `Prof. Dr. Yoshiyuki SANKAI`}
              </p>
              <p>
                {intl.locale === "en"
                  ? `President and CEO of Cyberdyne Inc.`
                  : `Präsident und Geschäftsführer der Cyberdyne Inc.`}
              </p>
            </div>
          </div>

          <div>
            <p className="message-lg-gray font-exo py-7">
              {intl.locale === "en"
                ? `The company name is derived from its utilization of innovative “Cybernics” technologies and the suffix “dyne” which is derived from the Greek word dunamis meaning force/power.`
                : `Der Firmenname leitet sich aus der Nutzung innovativer "Cybernics"-Technologien und dem Suffix "dyne" ab, das vom griechischen Wort dunamis für Kraft abgeleitet ist. `}
            </p>
            <div className="flex-row">
              <div className="flex-column-7">
                <h2 className="h3">
                  {intl.locale === "en"
                    ? `Main awards associated with Prof. Dr. Yoshiyuki SANKAI`
                    : `Wichtige mit Prof. Dr. Yoshiyuki SANKAI verbundene Auszeichnungen`}
                </h2>
                {intl.locale === "en" ? (
                  <ul>
                    <li>The World Technology Award (the Grand Prize) (2005)</li>
                    <li>The Good Design Award (the Gold Prize)</li>
                    <li>
                      The Japan Innovator Award (the Award of Excellence) (2006)
                    </li>
                    <li>
                      The Minister of Economy, Trade and Industry Award (2007)
                    </li>
                    <li>
                      The 21st Century Invention Award at the National Invention
                      Awards, “Development of Cyborg-type Robot Technology”
                      (2009)
                    </li>
                    <li>The NetExplorateurs at UNESCO Headquarters (2011)</li>
                    <li>The Capek Award (2012)</li>
                    <li>
                      The Technology Pioneer at the World Economic Forum (2013)
                    </li>
                    <li>The Edison Awards Gold Award</li>
                    <li>The DealWatch Awards 2013</li>
                    <li>The IPO of the Year at the Thomson Reuters (2014)</li>
                    <li>
                      The Minister of Education, Culture, Sports, Science and
                      Technology Award
                    </li>
                    <li>The DealWatch Awards 2014</li>
                    <li>
                      The Innovative Equity Deal of the Year at the Thomson
                      Reuters (2014)
                    </li>
                    <li>
                      The Minister of Economy, Trade and Industry Award, the
                      Zaikai Business Leader of the Year Award (2015)
                    </li>
                    <li>
                      The DIA Japan Outstanding Contribution to Health Award,
                      the Robot Award - Ministry of Health Labour and Welfare
                      Award (2016)
                    </li>
                    <li>
                      The Prime Ministers Award (the Nippon Venture Award)
                      (2017)
                    </li>
                    <li>
                      The Ministry of Education, Culture, Sports, Science and
                      Technology Award (the Technology Management and Innovation
                      Award)
                    </li>
                    <li>The Ichimura Prize in Industry(2019)</li>
                    <li>
                      The Medal of Honor with Purple Ribbon from Japanese
                      emperor (2019)
                    </li>
                    <li>
                      The Intellectual Property Achievement Awards (the Minister
                      of Economy Trade and Industry Awards) (2020) etc.
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>Der World Technology Award (Großer Preis) (2005)</li>
                    <li>
                      Der Good Design Award in Gold, der Japan Innovator Award
                      (Award of Excellence) (2006)
                    </li>
                    <li>
                      Der Minister of Economy, Trade and Industry Award (2007)
                    </li>
                    <li>
                      Der 21st Century Invention Award bei den National
                      Invention Awards
                    </li>
                    <li>
                      "Development of Cyborg-type Robot Technology" (2009)
                    </li>
                    <li>Der NetExplorateurs at UNESCO Headquarters (2011)</li>
                    <li>Der Capek Award (2012)</li>
                    <li>
                      Der Technology Pioneer at the World Economic Forum (2013)
                    </li>
                    <li>Edison Awards in Gold</li>
                    <li>DealWatch Awards 2013,</li>
                    <li>IPO of the Year bei Thomson Reuters (2014)</li>
                    <li>
                      Der Minister of Education, Culture, Sports, Science and
                      Technology Award
                    </li>
                    <li>DealWatch Awards 2014</li>
                    <li>
                      Der Innovative Equity Deal of the Year bei Thomson Reuters
                      (2014)
                    </li>
                    <li>
                      Der Minister of Economy, Trade and Industry Award, der
                      Zaikai Business Leader of the Year Award (2015)
                    </li>
                    <li>
                      Der DIA Japan Outstanding Contribution to Health Award,
                      Der Robot Award – Ministry of Health Labour and Welfare
                      Award (2016)
                    </li>
                    <li>
                      Der Prime Ministers Award (der Nippon Venture Award 2017)
                    </li>
                    <li>
                      Der Ministry of Education, Culture, Sports, Science and
                      Technology Award (der Technology Management and Innovation
                      Award)
                    </li>
                    <li>Der Ichimura Prize in Industry (2019)</li>
                    <li>
                      Die Medal of Honor with Purple Ribbon vom japanischen
                      Kaiser (2019)
                    </li>
                    <li>
                      Intellectual Property Achievement Awards (Minister of
                      Economy Trade and Industry Awards 2020)
                      <br />
                      und weitere Auszeichnungen.
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about us and the Cybernics Technology`
              : `Erfahren Sie mehr über uns und die Cybernics Technologie`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `About us` : `Über uns`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Our revolutionary technology is a game-changer of what we have
  as medical experts and neuro-rehabilitation professionals.`
                    : `Unsere revolutionäre Technologie ist bahnbrechend für medizinische Experten und Fachkräfte in der neurologischen Rehabilitation.`}
                </p>
                <Link to="/company/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Vision and Mission`
                    : `Vision und Auftrag`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `We envision a future where humans and technology are
  seamlessly interfaced, feeding off of each other.`
                    : `Wir stellen uns eine Zukunft vor, in der Menschen und Technologie nahtlos miteinander verbunden sind und sich gegenseitig unterstützen.`}
                </p>
                <Link to="/company/mission/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Cybernics</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `“Cybernics” (adjective: Cybernic) is a new academic field that
  is centered around cybernetics, mechatronics and informatics.`
                    : `“Cybernics" (Adjektiv: cybernic) ist ein neues akademisches Feld, das sich mit Kybernetik, Mechatronik und Informatik beschäftigt.`}
                </p>
                <Link to="/company/cybernics/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Contact/Access` : `Anfahrt`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Cyberdyne Care Robotics GmbH based in Bochum, North
  Rhine-Westphalia. We welcome people from all over the world.`
                    : `Die Cyberdyne Care Robotics GmbH hat ihren Sitz in Bochum, Nordrhein-Westfalen. Wir begrüßen Menschen aus der ganzen Welt.`}
                </p>
                <div>
                  <Link to="/company/access/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <FooterCta />
    </Layout>
  );
};

export default PageCompanIndex;
